import { Container } from "./styles";

const Button = ({
  onClick,
  children,
  backgroundColor,
  disabled,
  width,
  color,
}) => {
  return (
    <Container
      onClick={onClick}
      style={{ backgroundColor: backgroundColor, width: width, color: color }}
      disabled={disabled}
    >
      {children}{" "}
    </Container>
  );
};

export default Button;
