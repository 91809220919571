import styled from "styled-components";

import BackgroundDesktop from "../../assets/comandatuba/background_event_desktop.jpg";
import BackgroundTablet from "../../assets/comandatuba/background_event_tablet.jpg";
import BackgroundMobile from "../../assets/comandatuba/background_event_mobile.jpg";

export const Container = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  background-image: url(${BackgroundDesktop});
  background-repeat: no-repeat;
  background-position: right;
  background-color: #151515;

  padding: 100px 0px;

  @media (max-width: 1000px) {
    background-image: url(${BackgroundTablet});
    height: 100%;
    padding-bottom: 59px;
  }

  @media (max-width: 600px) {
    background-image: url(${BackgroundMobile});
    background-size: contain;
    padding-top: 60px;
    background-position: top;
  }
`;

export const Content = styled.div`
  width: 100%;
  margin-left: 8%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1200px) {
    margin-left: 5%;
  }

  @media (max-width: 500px) {
    align-items: center;
    margin: 0 5%;
  }
`;

export const Logo = styled.img`
  width: 100%;
  max-width: 372px;
  height: auto;
  margin-bottom: 82px;
`;

export const Description = styled.p`
  font-weight: 350;
  font-size: 20px;
  font-family: "GothamPro", sans-serif;
  color: white;
  width: 50%;
  height: auto;

  @media (max-width: 800px) {
    width: 80%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;
